*{
    margin: 0;
    padding: 0;
    text-decoration: none;
}
.container{
    display: flex;

}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    background-color: #192536;
    color: #fff;
    min-height: 100%;
    height: 120vh;
    width: 300px;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items: center;
    padding: 20px 15px;

}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left:50px ;

}
.link,.logout_text{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background-color: #f2f5f7;
    color: #27374D;
    transition: all 0.5s;
}
.active{
    background-color: #f2f5f7;
    color: #27374D;
}
.icon,.link_text,.logout_text{
    font-size: 20px;
}
.buttonGroup{
    align-items: center;
    text-align: center;
}